import React from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'

// components
import { Button } from '@/components/atoms'
import { salesMainContainerStyle } from '@/components/pages/sales-manager/sales/_detail/sales'
import { DatePicker } from '@/components/organisms/customer/_detail/date-picker'
import { Identification } from '@/components/organisms/customer/identification/identification'

// libs
import { genders } from '@/libs/genders'
import { occupationsWithOthers } from '@/libs/occupations'
import { countryList } from '@/libs/nationality'

// models
import { GuestInput, GuestInputErrors } from '@/models/guest-input'
import { CustomerType } from '@/models/customer-manager/customer'
import { RadioGroupField } from '@/components/molecules/radio-group-field'
import { isEmployee } from '@/models/self-checkin/accommodation-info'
import { initializeSelectBoxStyle } from '@/constants/form'

type AddGuestInfoModalProps = {
  showInput: boolean
  guestInput: GuestInput
  guestInputErrors: GuestInputErrors
  onChangeGuestInfo: (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => void
  onSelect: (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => void
  onChangeBirthDate: (value: string) => void
  onClickModalCancel: () => void
  onClickModalSave: () => void
  onClickDeleteImage: (e: any) => void
  customer: CustomerType
}

export const AddGuestInfoModal: React.FC<AddGuestInfoModalProps> = ({
  showInput,
  guestInput,
  guestInputErrors,
  onChangeGuestInfo,
  onSelect,
  onChangeBirthDate,
  onClickModalCancel,
  onClickModalSave,
  onClickDeleteImage,
  customer,
}) => {
  const { t } = useTranslation()

  const isOtherNationality = () => !!guestInput?.nationality && guestInput?.nationality !== 'JPN'

  return (
    <div css={modalWrapperStyle}>
      <div css={modalContentStyle}>
        <div css={modalTitleWrapperStyle}>
          <p css={modalTitleStyle}>{t('Edit check-in information')}</p>
        </div>
        <div css={addGuestInfoWrapperStyle}>
          <div css={showInput ? addGuestInpfoStyleShowInput : addGuestInfoStyle}>
            <div css={guestInfoItemStyle}>
              <p css={guestInfoLabelStyle}>{t('Country of Citizenship')}</p>
              <RadioGroupField
                value={isOtherNationality() ? 'NON' : guestInput?.nationality}
                name="nationality"
                items={[
                  {
                    value: 'JPN',
                    label: t('Japanese nationality'),
                  },
                  {
                    value: 'NON',
                    label: t('Foreign nationality'),
                  },
                ]}
                style={{ radioSize: 18, labelMargin: 8 }}
                itemWrapperCss={radioButtonWrapperStyle}
                itemCss={radioButtonStyle}
                onChange={onChangeGuestInfo}
              />
              {isOtherNationality() && (
                <>
                  <select
                    name="nationality"
                    css={guestInputErrors.nationality ? nationalitySelectErrorStyle : nationalitySelectStyle}
                    value={guestInput?.nationality}
                    onChange={onChangeGuestInfo}
                  >
                    <option value="">{t('Select country')}</option>
                    {countryList('ja', false).map((country, index) => {
                      return (
                        <option value={country.value} key={index}>
                          {country.name}
                        </option>
                      )
                    })}
                  </select>
                  {guestInputErrors.nationality && <p css={errorTextStyle}>{guestInputErrors.nationality}</p>}
                </>
              )}
            </div>

            <div css={guestInfoItemFlexStyle}>
              <div css={nameWrapperStyle}>
                <p css={guestInfoLabelStyle}>{t('Full name')}</p>
                <div css={guestInputWrapperStyle}>
                  <input
                    type="text"
                    css={guestInputErrors.lastName ? nameInputErrorStyle : nameInputStyle}
                    value={guestInput?.lastName}
                    name="lastName"
                    placeholder={t('Doe')}
                    onChange={onChangeGuestInfo}
                  />
                  {guestInputErrors.lastName && <p css={errorTextStyle}>{guestInputErrors.lastName}</p>}
                  <input
                    type="text"
                    css={guestInputErrors.firstName ? nameInputErrorStyle : nameInputStyle}
                    value={guestInput?.firstName}
                    name="firstName"
                    placeholder={t('John')}
                    onChange={onChangeGuestInfo}
                  />
                  {guestInputErrors.firstName && <p css={errorTextStyle}>{guestInputErrors.firstName}</p>}
                </div>
              </div>
            </div>

            <div css={guestInfoItemFlexStyle}>
              <div css={nameWrapperStyle}>
                <p css={guestInfoLabelStyle}>{t('Furigana')}</p>
                <div css={guestInputWrapperStyle}>
                  <input
                    type="text"
                    css={guestInputErrors.lastNameKana ? nameInputErrorStyle : nameInputStyle}
                    value={guestInput?.lastNameKana}
                    name="lastNameKana"
                    placeholder={t('Surname')}
                    onChange={onChangeGuestInfo}
                  />
                  {guestInputErrors.lastNameKana && <p css={errorTextStyle}>{guestInputErrors.lastNameKana}</p>}
                  <input
                    type="text"
                    css={guestInputErrors.firstName ? nameInputErrorStyle : nameInputStyle}
                    value={guestInput?.firstNameKana}
                    name="firstNameKana"
                    placeholder={t('May')}
                    onChange={onChangeGuestInfo}
                  />
                  {guestInputErrors.firstNameKana && <p css={errorTextStyle}>{guestInputErrors.firstNameKana}</p>}
                </div>
              </div>
            </div>

            <div css={guestInfoItemStyle}>
              <p css={guestInfoLabelStyle}>{t('Date of birth')}</p>
              <DatePicker
                value={guestInput?.birthDate}
                placeholder={t('Please select')}
                error={!!guestInputErrors.birthDate}
                onChange={onChangeBirthDate}
              />
              {guestInputErrors.birthDate && <p css={errorTextStyle}>{guestInputErrors.birthDate}</p>}
            </div>

            <div css={guestInfoItemStyle}>
              <p css={guestInfoLabelStyle}>{t('Sex')}</p>
              <RadioGroupField
                value={guestInput?.gender}
                name="gender"
                items={genders}
                style={{ radioSize: 18, labelMargin: 8 }}
                itemWrapperCss={radioButtonWrapperStyle}
                itemCss={radioButtonStyle}
                onChange={onChangeGuestInfo}
              />
            </div>

            <div css={guestInfoItemStyle}>
              <p css={guestInfoLabelStyle}>{t('Address')}</p>
              <input
                type="text"
                css={guestInputErrors.address ? addressInputErrorStyle : addressInputStyle}
                value={guestInput?.address}
                name="address"
                placeholder={t('1-1 Chiyoda Chiyoda-ku')}
                onChange={onChangeGuestInfo}
              />
              {guestInputErrors.address && <p css={errorTextStyle}>{guestInputErrors.address}</p>}
            </div>
            {/* こっから新規追加 */}
            <div css={guestInfoItemStyle}>
              <p css={guestInfoLabelStyle}>{t('Phone number')}</p>
              <input
                type="text"
                css={guestInputErrors.telephone ? telephoneInputErrorStyle : telephoneInputStyle}
                value={guestInput?.telephone}
                name="telephone"
                placeholder="00000000000"
                onChange={onChangeGuestInfo}
              />
              {guestInputErrors.telephone && <p css={errorTextStyle}>{guestInputErrors.telephone}</p>}
            </div>
            <div css={guestInfoItemStyle}>
              <p css={guestInfoLabelStyle}>{t('Email address')}</p>
              <input
                type="text"
                css={guestInputErrors.email ? emailInputErrorStyle : emailInputStyle}
                value={guestInput?.email ? guestInput?.email : ''}
                name="email"
                placeholder="xxx@cuicin.co.jp"
                onChange={onChangeGuestInfo}
              />
              {guestInputErrors.email && <p css={errorTextStyle}>{guestInputErrors.email}</p>}
            </div>
            <div css={guestInfoItemStyle}>
              <p css={guestInfoLabelStyle}>{t('Occupation')}</p>
              <select
                name="occupation"
                css={guestInputErrors.occupation ? occupationInputErrorStyle : occupationSelectStyle}
                value={guestInput?.occupation}
                onChange={onSelect}
              >
                <option value="">{t('Please select')}</option>
                {occupationsWithOthers.map((occupation, index) => {
                  return (
                    <option value={`${t(occupation.value)}`} key={index}>
                      {t(occupation.label)}
                    </option>
                  )
                })}
              </select>
              {showInput && (
                <input
                  type="text"
                  css={guestInputErrors.occupation ? occupationInputErrorStyle : occupationInputStyle}
                  value={guestInput.otherOccupation}
                  name="otherOccupation"
                  onChange={onChangeGuestInfo}
                />
              )}
              {!!guestInputErrors.occupation && <p css={errorTextStyle}>{guestInputErrors.occupation}</p>}
            </div>
            {isEmployee(guestInput.occupation) && (
              <div css={guestInfoItemStyle}>
                <p css={guestInfoLabelStyle}>{t('Company name')}</p>
                <input
                  type="text"
                  css={emailInputStyle}
                  value={guestInput.company ? guestInput.company : ''}
                  name="company"
                  placeholder={t('CUICIN Co Ltd')}
                  onChange={onChangeGuestInfo}
                />
              </div>
            )}

            <div css={guestInfoItemStyle}>
              <p css={guestInfoLabelStyle}>{t('E-mail delivery permission')}</p>
              <select
                css={[selectStyle, { width: '100%' }]}
                value={guestInput.deliveryAgreementStatus}
                name="deliveryAgreementStatus"
                onChange={onChangeGuestInfo}
              >
                <option value="unconfirmed">{t('Unconfirmed')}</option>
                <option value="deliverable">{t('Deliverable')}</option>
                <option value="nonDeliverable">{t('Non deliverable')}</option>
              </select>
            </div>
          </div>

          {/* Add ID */}
          <Identification
            checkinId={customer.id}
            identifications={customer.identification}
            hotelId={customer.hotelId}
            onClickDeleteImage={onClickDeleteImage}
          />
        </div>

        <div css={footerButtonWrapperStyle}>
          <Button width={110} height={38} marginRight={16} buttonType={3} onClick={onClickModalCancel}>
            {t('Cancel')}
          </Button>
          <Button width={110} height={38} buttonType={1} onClick={onClickModalSave}>
            {t('Save')}
          </Button>
        </div>
      </div>
    </div>
  )
}

const modalWrapperStyle = css(salesMainContainerStyle, {
  width: '100vw',
  height: '100vh',
  background: 'rgba(39, 39, 39, 0.5)',
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 103,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'scroll',
})

const modalContentStyle = css({
  width: 900,
  maxHeight: 650,
  background: '#fff',
  borderRadius: 5,
  marginBottom: 40,
})

const modalTitleWrapperStyle = css({
  width: 'inherit',
  paddingLeft: 32,
  position: 'absolute',
  'z-index': '10000',
  backgroundColor: 'white',
  borderRadius: '5px 5px 0px 0px',
})

const modalTitleStyle = css({
  color: '#272727',
  fontSize: 18,
  fontWeight: 'bold',
  lineHeight: '60px',
  letterSpacing: '0.9px',
})

const addGuestInfoWrapperStyle = css({
  width: '100%',
  padding: '60px 32px 32px',
  background: '#f2f2f2',
  display: 'flex',
  justifyContent: 'space-between',
  borderRadius: '5px 5px 0px 0px',
})

const addGuestInfoStyle = css({
  width: 445,
  padding: 32,
  background: '#fff',
  border: '1px solid #ccc',
  borderRadius: 5,
  marginTop: 24,
})

const addGuestInpfoStyleShowInput = css({
  width: 445,
  padding: 32,
  background: '#fff',
  border: '1px solid #ccc',
  borderRadius: 5,
  height: 'fit-content',
  marginTop: 52,
})

const guestInfoItemStyle = css({
  marginBottom: 24,

  '&:last-child': {
    marginBottom: 0,
  },
})

const guestInfoItemFlexStyle = css(guestInfoItemStyle, {
  display: 'flex',
})

const guestInfoLabelStyle = css({
  marginBottom: 12,
  color: '#676767',
  fontSize: 12,
  fontWeight: 'bold',
  letterSpacing: '0.6px',
})

const radioButtonWrapperStyle = css({
  '& + &': {
    marginLeft: 40,
  },
})

const radioButtonStyle = css({
  label: {
    color: '#272727',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.9px',
  },
})

const nameWrapperStyle = css({
  marginRight: 15,
})

const inputStyle = css({
  padding: 0,
  paddingLeft: 16,
  color: '#272727',
  fontSize: 14,
  lineHeight: '30px',
  border: '1px solid #ccc',
  borderRadius: 16,

  '&::placeholder': {
    color: '#ccc',
  },
})

const errorStyle = css({
  border: '1px solid #F2A40B',
})

const nameInputStyle = css(inputStyle, {
  width: 183,
  marginRight: 15,
})

const nameInputErrorStyle = css(nameInputStyle, errorStyle)

const addressInputStyle = css(inputStyle, {
  width: '100%',
})
const telephoneInputStyle = css(inputStyle, {
  width: '100%',
})
const emailInputStyle = css(inputStyle, {
  width: '100%',
})

const addressInputErrorStyle = css(addressInputStyle, errorStyle)
const telephoneInputErrorStyle = css(telephoneInputStyle, errorStyle)
const emailInputErrorStyle = css(emailInputStyle, errorStyle)

const selectStyle = css(initializeSelectBoxStyle, {
  paddingLeft: 16,
  color: '#272727',
  fontSize: 14,
  lineHeight: '30px',
  border: '1px solid #ccc',
  borderRadius: 16,
  backgroundImage: `url(${require('@/static/images/select.svg')})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right 14px center',
  backgroundSize: 8,
})

const occupationInputErrorStyle = css(selectStyle, errorStyle)
const occupationInputStyle = css(inputStyle, {
  width: '100%',
  marginTop: '24px',
})

const occupationSelectStyle = css(selectStyle, {
  width: '100%',
})

const nationalitySelectStyle = css(selectStyle, {
  width: 167,
  marginTop: 12,
})

const nationalitySelectErrorStyle = css(nationalitySelectStyle, errorStyle)

const errorTextStyle = css({
  marginTop: 14,
  color: '#F2A40B',
  fontSize: 12,
  fontWeight: 'bold',
})

const footerButtonWrapperStyle = css({
  height: 60,
  paddingRight: 32,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  width: 'inherit',
  position: 'absolute',
  'z-index': '10000',
  backgroundColor: 'white',
  borderRadius: '0px 0px 5px 5px',
})

const guestInputWrapperStyle = css({
  display: 'flex',
})
