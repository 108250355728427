import uuid from 'react-uuid'
import b64toBlob from 'b64-to-blob'
import { useTranslation } from 'react-i18next'
import { useContext } from 'react'
import { AccountContext } from '@/contexts/account'
import { getUploadKey } from '@/apis/aipass'
import { env } from '@/libs/env'
import axios, { AxiosError } from 'axios'

export const useFileUpload = () => {
  const { t } = useTranslation()
  const { account } = useContext<any>(AccountContext)

  const inputFileHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    allowMineType: string[],
    onFileUploaded: (result: { uploadedPath: string; file: File }) => void,
  ) => {
    const files = event.target.files
    if (!files?.length) {
      return
    }
    const file = files[0]
    if (!allowMineType.includes(file?.type)) {
      window.alert(t('UnsupportedFileFormats'))
      return
    }
    const allowMaxFileByte = 10 * 1024 * 1024
    if (file.size > allowMaxFileByte) {
      window.alert(t('FileSizeTooLarge'))
      return
    }

    const reader = new FileReader()
    reader.onload = async e => {
      const fileData = e.target ? e.target.result : null
      if (fileData) {
        const uploadedPath = await upload({
          hotelId: account.employee.hotelId,
          file,
          fileData: fileData.toString(),
        })

        onFileUploaded({ uploadedPath, file })
      }
    }
    reader.readAsDataURL(file)
  }

  const upload = async ({
    hotelId,
    file,
    fileData,
    filePath,
    isPublic = true,
  }: {
    hotelId: string
    file: { name: string; type: string }
    fileData: string | Blob
    filePath?: string
    isPublic?: boolean
  }): Promise<string> => {
    let fileDataBlob
    if (typeof fileData === 'string') {
      const encodeTrimmedFileData = fileData.replace(`data:${file.type};base64,`, '')
      fileDataBlob = b64toBlob(encodeTrimmedFileData, file.type)
    } else {
      fileDataBlob = fileData
    }
    const objectKey = filePath || `${hotelId}/${uuid()}/${file.name}`
    return await uploadS3({
      objectKey,
      isPublic,
      contentType: file.type,
      file: fileDataBlob,
    })
  }

  const uploadS3 = async (params: { objectKey: string; isPublic: boolean; contentType: string; file: Blob }) => {
    const uploadKey = await getUploadKey({
      isPublic: params.isPublic,
      objectKey: params.objectKey,
      contentType: params.contentType,
      fileSize: params.file.size,
    })
    const form = new FormData()
    const s3url = `https://${env('AWS_BUCKET_NAME')}.s3.amazonaws.com/`
    form.append('key', params.objectKey)
    form.append('acl', params.isPublic ? 'public-read' : 'private')
    form.append('policy', uploadKey.policy)
    form.append('X-Amz-Credential', uploadKey.credential)
    form.append('X-Amz-Algorithm', 'AWS4-HMAC-SHA256')
    form.append('X-Amz-Date', uploadKey.signedDate)
    form.append('X-Amz-Signature', uploadKey.signature)
    form.append('Content-Type', params.contentType)
    form.append('file', params.file)
    await axios.post(s3url, form).catch((e: AxiosError) => {
      if (e.isAxiosError) {
        console.error(e.response?.data)
      } else {
        console.error(e)
      }
      alert('ファイルのアップロードに失敗しました')
      throw e
    })
    return `${s3url}${params.objectKey}`
  }

  return { inputFileHandler, upload }
}
