import React, { useState, useEffect, useCallback, useContext } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import Slider from '@material-ui/core/Slider'
import Cropper from 'react-easy-crop'
import getCroppedImg from './cropImage'
import uuid from 'react-uuid'
import { EditorState } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { fetchFacilityBasicInfo, updateFacilityBasicInfo, updateFacilityAdditionalInfo, fetchFacilityAdditionalInfo } from '@/apis/aipass'
import { times } from '@/libs/times'
import { listContainerStyle } from '@/constants/layout'
import { Editor, editorOptions } from '@/components/organisms/editor'
import { Button } from '@/components/atoms/button'
import { FacilityPageIndex } from '@/models/facility'
import { FacilityTranslateText } from '@/libs/translate-text'
import { SettingsLayout } from '@/components/layouts/settings-layout'
import { EditFooter } from '@/components/organisms/edit-footer'
import { PluginCode, checkActivePlugin } from '@/libs/plugins'
import { AccountContext } from '@/contexts/account'
import { initializeSelectBoxStyle } from '@/constants/form'
import { useFileUpload } from '@/hooks/use-file-upload'

export const SettingAccountEdit: React.FC = () => {
  const { t } = useTranslation()
  const location = useLocation<any>()
  const history = useHistory()
  const { hotelStatus } = location.state
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [jpContent, setJpContent] = useState<string>('')
  const [additionalInfo, setAdditionalInfo] = useState<any>()
  const [isValidate, setIsValidate] = useState<boolean>(false)
  const { plugins } = useContext<any>(AccountContext)
  const guestAppPluginActive = checkActivePlugin(plugins, PluginCode.GuestApp)

  //Hooks
  const [hotelName, setHotelName] = useState<string>('')
  const [hotelNameLatin, setHotelNameLatin] = useState<string>('')
  const [logoImageId, setLogoImageId] = useState<any>('')
  const [extImageId, setExtImageId] = useState<any>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [telephone, setFrontTelephone] = useState<string>('')
  const [checkinBeginTime, setCheckinBeginTime] = useState<string>('')
  const [checkinEndTime, setCheckInEndTime] = useState<string>('')
  const [checkoutBeginTime, setCheckoutBeginTime] = useState<string>('')
  const [checkoutEndTime, setCheckoutEndTime] = useState<string>('')
  const [frontOpenTime, setFrontOpenTime] = useState<string>('')
  const [frontCloseTime, setFrontCloseTime] = useState<string>('')
  const [receiptHotelName, setReceiptHotelName] = useState<string>('')
  const [receiptAddress, setReceiptAddress] = useState<string>('')
  const [receiptTelephone, setReceiptTelephone] = useState<string>('')
  const [displayLogoAnnotation, setDisplayLogoAnnotation] = useState<boolean>(false)
  const [displayAnnotation, setDisplayAnnotation] = useState<boolean>(false)
  const { upload } = useFileUpload()

  // cropModal
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [cropModalOpen, setCropModalOpen] = useState(false)
  const [cropModalType, setCropModalType] = useState('')
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [basicInfo, setBasicInfo] = useState<any>({})

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  // File upload process
  const handleChangeFile = (e: any, type: string) => {
    const files = e.target.files
    if (files.length > 0) {
      const file = files[0]
      const reader = new FileReader()
      reader.onload = e => {
        const imageUrl = e.target ? e.target.result : null
        if (type === 'logo') {
          setLogoImageId(imageUrl)
        } else if (type === 'ext') {
          setExtImageId(imageUrl)
        }
        setCropModalOpen(true)
        setCropModalType(type)
      }
      reader.readAsDataURL(file)
    } else {
      console.log(t('Upload failed'))
    }
  }

  // Image processing
  const showCroppedImage = async (cropModalType: string) => {
    const croppedImage: any = await getCroppedImg(cropModalType === 'logo' ? logoImageId : extImageId, croppedAreaPixels)
    const imageUrl = await upload({
      hotelId: basicInfo.hotelId,
      file: { name: `${uuid()}.jpeg`, type: 'image/jpeg' },
      fileData: croppedImage,
    })
    cropModalType === 'logo' ? setLogoImageId(imageUrl) : setExtImageId(imageUrl)
    setCropModalOpen(false)
  }

  // Modal cancellation processing
  const onCloseCropModal = type => {
    if (type === 'logo') {
      setLogoImageId(hotelStatus.logoImageId)
    } else if (type === 'ext') {
      setExtImageId(hotelStatus.extImageId)
    }
    setCropModalOpen(false)
  }

  // Hotel detail update process
  const onSaveData = () => {
    if (hotelName !== '' && hotelNameLatin !== '') {
      _updateFacilityBasicInfo()
      _wifiSubmit()
      setIsLoading(true)
    }
  }

  const _updateFacilityBasicInfo = async () => {
    const { pageId } = basicInfo
    const data = {
      hotelName,
      hotelNameLatin,
      telephone,
      checkinBeginTime,
      checkinEndTime,
      checkoutBeginTime,
      checkoutEndTime,
      frontOpenTime,
      frontCloseTime,
      logoImageId,
      extImageId,
      pageId,
      receiptHotelName,
      receiptAddress,
      receiptTelephone,
    }
    await updateFacilityBasicInfo(data)
      .then(() => {
        setIsLoading(false)
        history.push({ pathname: '/setting/account' })
      })
      .catch(() => {
        console.log(t('Communication failed'))
      })
  }

  useEffect(() => {
    setIsValidate(hotelName !== '' && hotelNameLatin !== '')
  }, [hotelNameLatin, hotelName])

  useEffect(() => {
    _fetchFacilityBasicInfo()
    _fetchFacilityAdditionalInfo()
  }, [])

  const _fetchFacilityBasicInfo = async () => {
    await fetchFacilityBasicInfo()
      .then(res => {
        if (res !== undefined) {
          setBasicInfo(res?.basicInfo)
          setHotelName(res?.basicInfo.hotelName)
          setHotelNameLatin(res?.basicInfo.hotelNameLatin)
          setFrontTelephone(res?.basicInfo.telephone)
          setCheckinBeginTime(res?.basicInfo.checkinBeginTime)
          setCheckInEndTime(res?.basicInfo.checkinEndTime)
          setCheckoutBeginTime(res?.basicInfo.checkoutBeginTime)
          setCheckoutEndTime(res?.basicInfo.checkoutEndTime)
          setFrontOpenTime(res?.basicInfo.frontOpenTime)
          setFrontCloseTime(res?.basicInfo.frontCloseTime)
          setLogoImageId(res?.basicInfo.logoImageId)
          setExtImageId(res?.basicInfo.extImageId)
          setReceiptHotelName(res?.basicInfo.receiptHotelName)
          setReceiptAddress(res?.basicInfo.receiptAddress)
          setReceiptTelephone(res?.basicInfo.receiptTelephone)
          setIsLoading(false)
        }
      })
      .catch(() => {
        console.log(t('Communication failed'))
      })
  }

  // Acquisition of in-house guidance information
  const _fetchFacilityAdditionalInfo = async () => {
    await fetchFacilityAdditionalInfo(FacilityPageIndex.WifiTop).then(res => {
      if (res !== undefined) {
        setAdditionalInfo(res?.facilityAdditionalPageInfo)
        setJpContent(res?.facilityAdditionalPageInfo.jpContent)
      }
    })
  }
  const _wifiSubmit = async () => {
    setIsLoading(true)
    const jpContent = stateToHTML(editorState.getCurrentContent(), editorOptions).replace(/\r?\n/g, '</br>')
    const pageId = additionalInfo?.pageId ? additionalInfo?.pageId : null
    const pageIndex = FacilityPageIndex.WifiTop
    await FacilityTranslateText({ pageId, pageIndex, jpContent }).then(data => {
      updateFacilityAdditionalInfo(data)
        .then(() => {
          setIsLoading(false)
          history.push({ pathname: '/setting/account', search: location.search })
          window.location.reload()
        })
        .catch(error => {
          console.log(error)
        })
    })
  }

  const footerContent = <EditFooter onCancel={history.goBack} onSave={onSaveData} isDisabledSave={!isValidate} />

  return (
    <SettingsLayout loading={isLoading} footerContent={footerContent}>
      <div css={hotelAccountDetailsContainerStyle}>
        <div css={detailsHeaderContainerStyle}>
          <div css={detailsHeaderTitleStyle}>{t(t(guestAppPluginActive ? 'Facility information' : 'Facility guidance'))}</div>
        </div>
        <div css={detailsMainContainerStyle}>
          <ul css={detailsMainLeftContainerStyle}>
            <li css={hotelLogoList}>
              <div css={hotelLogoHelpDiv}>
                <p css={mainLeftTopLabelStyle}>{t('Hotel logo')}</p>
                <img
                  css={helpIconStyle}
                  src={require('@/static/images/icon_help.svg')}
                  onMouseEnter={() => setDisplayLogoAnnotation(true)}
                  onMouseLeave={() => setDisplayLogoAnnotation(false)}
                ></img>
              </div>
              <div css={hotelLogoImageWrapperStyle}>
                <label css={hotelLogoImageInputStyle} htmlFor="hotelLogoImage" />
                {!logoImageId && (
                  <input
                    type="file"
                    id="hotelLogoImage"
                    css={none}
                    onChange={e => handleChangeFile(e, 'logo')}
                    onClick={(e: any) => {
                      e.target.value = ''
                    }}
                  />
                )}
                {logoImageId && (
                  <>
                    <input
                      type="file"
                      id="hotelLogoImage"
                      css={hotelLogoImageInputNoneStyle}
                      onChange={e => handleChangeFile(e, 'logo')}
                      onClick={(e: any) => {
                        e.target.value = ''
                      }}
                    />
                    <div css={hotelLogoImage} style={{ backgroundImage: `url(${logoImageId ? logoImageId : null})` }}></div>
                  </>
                )}
              </div>
              {displayLogoAnnotation && <div css={imageAnnotationModal}></div>}
            </li>
          </ul>
          {cropModalOpen && (
            <div css={cropModalContainerStyle}>
              <div css={cropModalBackgroundStyle} />
              <div css={cropModalMainContainerStyle}>
                <div className="crop-container" css={cropModalType === 'logo' ? cropperLogoInsideStyle : cropperHotelPictureInsideStyle}>
                  {cropModalType === 'logo' ? (
                    <div css={cropModalHeaderStyle}>{t('Set hotel logo')}</div>
                  ) : (
                    <div css={cropModalHeaderStyle}>{t('Set hotel image')}</div>
                  )}
                  <div css={cropWrapperStyle}>
                    <div css={cropTopSpaceStyle} />
                    <div css={cropperStyle}>
                      <Cropper
                        image={cropModalType === 'logo' ? logoImageId : extImageId}
                        crop={crop}
                        zoom={zoom}
                        aspect={cropModalType === 'logo' ? 1 : 2 / 1}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                      />
                    </div>
                    <div css={cropBottomSpaceStyle} />
                  </div>
                  <div css={cropModalFooterStyle}>
                    <div css={cropModalSliderContainerStyle}>
                      <img src={require('@/static/images/picture.svg')} css={sliderIconSmStyle} />
                      <Slider value={zoom} min={1} max={3} step={0.1} aria-labelledby="Zoom" onChange={(e, zoom: any) => setZoom(zoom)} />
                      <img src={require('@/static/images/picture.svg')} css={sliderIconLgStyle} />
                    </div>
                    <div css={cropModalButtonContainerStyle}>
                      <Button buttonType={3} width={110} height={38} marginLeft={16} onClick={() => onCloseCropModal(cropModalType)}>
                        {t('Cancel')}
                      </Button>
                      <Button buttonType={1} width={110} height={38} marginLeft={16} onClick={() => showCroppedImage(cropModalType)}>
                        {t('Apply')}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <ul css={detailMainRightContainerStyle}>
            <li css={hotelImageList}>
              <div css={hotelLogoHelpDiv}>
                <p css={mainLeftLabelStyle}>{t('Hotel image')}</p>
                <img
                  css={helpIconStyle}
                  src={require('@/static/images/icon_help.svg')}
                  onMouseEnter={() => setDisplayAnnotation(true)}
                  onMouseLeave={() => setDisplayAnnotation(false)}
                ></img>
              </div>
              <div css={hotelPictureWrapperStyle}>
                <label htmlFor="hotelPicture" css={hotelPictureStyle}></label>
                {!extImageId && (
                  <input
                    type="file"
                    id="hotelPicture"
                    css={hotelPictureInputNoneStyle}
                    onChange={e => handleChangeFile(e, 'ext')}
                    onClick={(e: any) => {
                      e.target.value = ''
                    }}
                  />
                )}
                {extImageId && (
                  <>
                    <input
                      type="file"
                      id="hotelPicture"
                      css={hotelPictureInputNoneStyle}
                      style={{ backgroundImage: `url(${extImageId ? extImageId : null})` }}
                      onChange={e => handleChangeFile(e, 'ext')}
                      onClick={(e: any) => {
                        e.target.value = ''
                      }}
                    />
                    <div css={hotelImageStyle} style={{ backgroundImage: `url(${extImageId ? extImageId : null})` }}></div>
                  </>
                )}
              </div>
              {displayAnnotation && <div css={hotelIimageAnnotationModal}></div>}
            </li>
          </ul>
        </div>
        <div css={detailsMainContainerStyle}>
          <ul css={detailsMainLeftContainerStyle}>
            <li css={facilityNameList}>
              <div css={mainLeftTopLabelStyle}>{t('Name of facility')}</div>
              <div css={listDetailTitle}>{t('A name of a hotel')}</div>
              <input
                css={hotelInputContentsStyle}
                type="text"
                placeholder={'HOTEL CUICIN'}
                value={hotelName ? hotelName : ''}
                onChange={e => (e.target.value ? setHotelName(e.target.value) : setHotelName(''))}
              />
            </li>
            <li css={facilityNameList}>
              <div css={listDetailTitle}>{t('Hotel name (English display)')}</div>
              <input
                css={hotelInputContentsStyle}
                type="text"
                placeholder={'HOTEL CUICIN'}
                value={hotelNameLatin ? hotelNameLatin : ''}
                onChange={e => (e.target.value ? setHotelNameLatin(e.target.value) : setHotelNameLatin(''))}
              />
            </li>
          </ul>
          <ul css={detailMainRightContainerStyle}>
            <div css={checkinOutLists}>
              <div css={mainLeftTopLabelStyle}>{t('Check-in/out')}</div>
              <li>
                <div css={listDetailTitle}>{t('Check-in time')}</div>
                <div css={basicDetailContentsSelectContainerStyle}>
                  <select
                    css={basicDetailContentsSelectBoxStyle}
                    value={checkinBeginTime}
                    onChange={e => setCheckinBeginTime(e.target.value)}
                  >
                    {times.map((item, index) => (
                      <option key={index} value={item['value']}>
                        {item['name']}
                      </option>
                    ))}
                  </select>
                  <div css={basicDetailContentsSepStyle}>〜</div>
                  <select css={basicDetailContentsSelectBoxStyle} value={checkinEndTime} onChange={e => setCheckInEndTime(e.target.value)}>
                    {times.map((item, index) => (
                      <option key={index} value={item['value']}>
                        {item['name']}
                      </option>
                    ))}
                  </select>
                </div>
              </li>
              <li>
                <div css={listDetailTitle}>{t('Check out time')}</div>
                <div css={basicDetailContentsSelectContainerStyle}>
                  <select
                    css={basicDetailContentsSelectBoxStyle}
                    value={checkoutBeginTime}
                    onChange={e => setCheckoutBeginTime(e.target.value)}
                  >
                    {times.map((item, index) => (
                      <option key={index} value={item['value']}>
                        {item['name']}
                      </option>
                    ))}
                  </select>
                  <div css={basicDetailContentsSepStyle}>〜</div>
                  <select
                    css={basicDetailContentsSelectBoxStyle}
                    value={checkoutEndTime}
                    onChange={e => setCheckoutEndTime(e.target.value)}
                  >
                    {times.map((item, index) => (
                      <option key={index} value={item['value']}>
                        {item['name']}
                      </option>
                    ))}
                  </select>
                </div>
              </li>
            </div>
          </ul>
        </div>
        <div css={detailsMainContainerStyle}>
          <ul css={detailsMainLeftContainerStyle}>
            <div css={frontInfoLists}>
              <div css={mainLeftTopLabelStyle}>{t('Front desk information')}</div>
              <li>
                <p css={listDetailTitle}>{t('Front phone number')}</p>
                <input
                  type="text"
                  css={hotelInputContentsStyle}
                  placeholder={'08012345678'}
                  value={telephone}
                  onChange={e => setFrontTelephone(e.target.value)}
                />
              </li>
              <li>
                <div css={listDetailTitle}>{t('Reception opening hours')}</div>
                <div css={basicDetailContentsSelectContainerStyle}>
                  <select css={basicDetailContentsSelectBoxStyle} value={frontOpenTime} onChange={e => setFrontOpenTime(e.target.value)}>
                    {times.map((item, index) => (
                      <option key={index} value={item['value']}>
                        {item['name']}
                      </option>
                    ))}
                  </select>
                  <div css={basicDetailContentsSepStyle}>〜</div>
                  <select css={basicDetailContentsSelectBoxStyle} value={frontCloseTime} onChange={e => setFrontCloseTime(e.target.value)}>
                    {times.map((item, index) => (
                      <option key={index} value={item['value']}>
                        {item['name']}
                      </option>
                    ))}
                  </select>
                </div>
              </li>
            </div>
          </ul>
          {!guestAppPluginActive && (
            <ul css={detailMainRightContainerStyle}>
              <li css={WifiList}>
                <div css={mainLeftTopLabelStyle}>Wi-Fi</div>
                <Editor jpContent={jpContent} editorState={editorState} setEditorState={setEditorState} usedIn="wifi" />
              </li>
            </ul>
          )}
        </div>
      </div>
    </SettingsLayout>
  )
}

// topStyle
const hotelAccountDetailsContainerStyle = css(listContainerStyle, {
  width: '100%',
  background: '#FFF',
  minHeight: 410,
})

const detailsHeaderContainerStyle = css({
  height: 50,
  padding: '0 32px',
  borderBottom: '1px solid #F2F2F2',
})

const detailsHeaderTitleStyle = css({
  fontSize: 18,
  fontWeight: 'bold',
  padding: '16px 0',
})

const detailsMainContainerStyle = css({
  display: 'flex',
  borderBottom: '1px solid #F2F2F2',
})

const detailsMainLeftContainerStyle = css({
  width: '50%',
  borderRight: '1px solid #F2F2F2',
})

const mainLeftTopLabelStyle = css({
  fontSize: 16,
  fontWeight: 'bold',
  color: '#272727',
  marginRight: 10,
  marginBottom: 20,
})
const mainLeftLabelStyle = css(mainLeftTopLabelStyle)
const hotelLogoImageWrapperStyle = css({
  width: 120,
  margin: '0 auto',
  img: {
    top: '38%',
    left: '38%',
    zIndex: 1,
  },
})

const hotelLogoImageInputStyle = css({
  cursor: 'pointer',
  width: 120,
  height: 120,
  '&::after': {
    display: 'inline-block',
    width: 120,
    height: 120,
    borderRadius: '50%',
    backgroundSize: '20%',
    content: '""',
    verticalAlign: 'middle',
    backgroundImage: `url(${require('@/static/images/camera_icon_white.svg')})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
})
const hotelLogoImageInputNoneStyle = css({
  position: 'absolute',
  display: 'none',
})

const hotelPictureWrapperStyle = css({
  img: {
    top: '44%',
    left: '47%',
    zIndex: 1,
  },
})

const hotelPictureInputNoneStyle = css(hotelLogoImageInputNoneStyle)

const hotelPictureStyle = css({
  '&::after': {
    display: 'inline-block',
    width: '100%',
    height: 159,
    content: '""',
    verticalAlign: 'middle',
    backgroundImage: `url(${require('@/static/images/camera_icon_white.svg')})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
    backgroundSize: 30,
    borderRadius: 5,
  },
})

// cropModal
const cropModalBackgroundStyle = css({
  position: 'absolute',
  width: '100%',
  height: '100%',
  left: 0,
  top: 0,
  background: 'black',
  opacity: 0.5,
})

const cropModalContainerStyle = css({
  zIndex: 102,
})

const cropModalMainContainerStyle = css({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  '-webkit-transform': 'translate(-50%, -50%)',
  '-ms-transform': 'translate(-50%, -50%)',
  width: 600,
  height: 450,
  background: 'white',
  borderRadius: '5px',
  zIndex: 1,
})

const cropperHotelPictureInsideStyle = css({
  height: '100%',
  position: 'relative',
  '.reactEasyCrop': {
    '&_CropArea': {
      boxShadow: '0 0 0 9999em rgba(0,0,0,0.1)',
      border: '2px solid #F2A40B',
      ':before': {
        border: 'none',
      },
      ':after': {
        border: 'none',
      },
    },
  },
})

const cropperLogoInsideStyle = css(cropperHotelPictureInsideStyle, {
  '.reactEasyCrop': {
    '&_CropArea': {
      width: '260px !important',
      height: '260px !important',
      borderRadius: '50%',
    },
  },
})

const cropModalHeaderStyle = css({
  zIndex: 1,
  background: '#FFF',
  width: '100%',
  height: 50,
  padding: '16px 0px 16px 32px',
  fontSize: '18px',
  color: '#CCCCCC',
  fontWeight: 'bold',
  borderRadius: '5px 5px 0 0',
  boxShadow: '0px 1px 6px rgba(0,0,0,0.10)',
})

const cropModalFooterStyle = css({
  position: 'absolute',
  bottom: 0,
  display: 'flex',
  padding: '14px 32px',
  width: '100%',
  justifyContent: 'space-between',
  background: '#FFF',
  borderRadius: '0 0 5px 5px',
})

const cropModalSliderContainerStyle = css({
  display: 'flex',
  alignItems: 'center',
  width: 210,
  '.MuiSlider': {
    '&-rail': {
      height: 1,
      color: '#CCC',
    },
    '&-root': {
      color: 'rgb(242, 164, 11)',
    },
    '&-thumb': {
      color: '#FFF',
      border: '2px solid rgb(242, 164, 11)',
      borderRadius: '50%',
      ':hover': {
        boxShadow: '0px 0px 0px 8px rgb(242, 164, 11, 0.2)',
      },
    },
  },
})

const cropWrapperStyle = css({
  width: 600,
  height: 340,
  position: 'relative',
  padding: '40px 0',
})

const cropTopSpaceStyle = css({
  width: '100%',
  height: 40,
  backgroundColor: '#e5e5e5',
  top: '0px',
  position: 'absolute',
})

const cropBottomSpaceStyle = css({
  width: '100%',
  height: 40,
  backgroundColor: '#e5e5e5',
  bottom: '0px',
  position: 'absolute',
})

const cropperStyle = css({
  position: 'absolute',
  top: '40px',
  left: 0,
  right: 0,
  bottom: '40px',
  height: 260,
})

const sliderIconSmStyle = css({
  width: 20,
  paddingRight: 8,
})

const sliderIconLgStyle = css({
  paddingLeft: 8,
})

const cropModalButtonContainerStyle = css({
  display: 'flex',
})

const detailMainRightContainerStyle = css({
  width: '50%',
})

const hotelInputContentsStyle = css({
  border: '1px solid #CCCCCC',
  borderRadius: 16,
  height: 32,
  width: '90%',
  padding: '0 16px',
  marginBottom: 5,
  '::placeholder': {
    color: '#CCCCCC',
  },
})

const hotelLogoList = css({
  margin: 32,
})
const hotelImageList = css(hotelLogoList)
const facilityNameList = css(hotelLogoList)
const checkinOutLists = css(hotelLogoList)
const WifiList = css(hotelLogoList, {
  margin: 35,
})
const frontInfoLists = css(hotelLogoList)

const listDetailTitle = css({
  fontSize: '12px',
  fontWeight: 'bold',
  color: '#676767',
  margin: '10px 0 10px 0',
})
const basicDetailContentsSelectBoxStyle = css(initializeSelectBoxStyle, {
  width: 115,
  height: 32,
  border: '1px solid #CCCCCC',
  borderRadius: 16,
  padding: '0 30px 0 16px',
  backgroundImage: `url(${require('@/static/images/select.svg')})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '85px center',
  backgroundSize: 10,
  display: 'block',
  cursor: 'pointer',
})
const basicDetailContentsSelectContainerStyle = css({
  display: 'flex',
  alignItems: 'center',
})
const basicDetailContentsSepStyle = css({
  margin: '0 8px',
})

const modalStyle = css({
  '&::after': {
    boxShadow: '0 10px 25px 0 #90979e7a',
    padding: '15px 15px 15px 11px',
    fontSize: '12px',
    color: '#272727',
    borderRadius: 5,
    lineHeight: 1.5,
    zIndex: 103,
    display: 'inline-block',
    whiteSpace: 'pre-wrap',
    backgroundSize: 'contain',
    verticalAlign: 'middle',
    backgroundColor: '#fff',
  },
})
const imageAnnotationModal = css(modalStyle, {
  '&::after': {
    width: 260,
    margin: '-330px 0px -28px 121px',
    content: '"※使用できる画像形式は、jpg、pngのみです\\A※最大サイズ　240px×240px\\A※推奨サイズ　160px×160px"',
    whiteSpace: 'pre',
  },
})

const hotelIimageAnnotationModal = css(modalStyle, {
  '&::after': {
    width: 280,
    margin: '-450px 0px 0px 121px',
    content: '"※使用できる画像形式は、jpg、pngのみです。\\A※最大サイズ　2304px×984px \\A※推奨サイズ　1536px×656px"',
  },
})

const hotelLogoImage = css({
  width: 120,
  height: 120,
  borderRadius: '50%',
  backgroundPosition: 'center center',
  backgroundSize: '100%',
  backgroundRepeat: 'no-repeat',
  margin: '-120px 0px 0px 0px',
})

const hotelImageStyle = css({
  width: '100%',
  height: 158,
  margin: '-158px 0 0 0',
  borderRadius: 5,
  backgroundSize: 'cover',
})

const hotelLogoHelpDiv = css({
  display: 'flex',
  paddingBottom: 10,
  alignItems: 'flex-start',
})

const helpIconStyle = css({
  cursor: 'pointer',
})

const none = css({
  display: 'none',
})
