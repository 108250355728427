import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { sectionHeaderStyle, sectionStyle } from '@/components/pages/dashboard/_index/style'
import { listContentsStyle, listItemUnreadStyle, listItemReadStyle } from '@/constants/layout'
import { putAdminReadReservationNote } from '@/apis/aipass'
import { DashboardNote, isCleaningMemo, isFrontMemo, isMealMemo } from '@/models/reservation/reservation-note'
import { CustomerTag } from '@/components/atoms/customer/customer-tag'

type memoProps = {
  dashboardValue: any
}

export const Memo: React.FC<memoProps> = ({ dashboardValue }) => {
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const memoList: DashboardNote[] = dashboardValue?.notes

  const changePage = (memo: DashboardNote) => {
    // When booking and check-in are unlinked
    if (memo.checkinId) {
      return history.push({
        pathname: `/checkin/${memo.checkinId}`,
        search: location.search,
      })
    }
    return history.push({
      pathname: `/reservation/${memo.id}`,
      search: `?reservationId=${memo.reservationId}`,
    })
  }

  return (
    <div css={mixedSectionStyle}>
      <div css={sectionHeaderStyle}>{t('Memo')}</div>
      <div css={headerWrapperStyle}>
        <div css={titleRoomNumberStyle}>{t('Room number')}</div>
        <div css={titleNameStyle}>{t('Guest')}</div>
        <div css={titleCustomerTagStyle}>{t('CustomerTag')}</div>
        <div css={titleMemoStyle}>{t('Memo')}</div>
      </div>
      <div css={mixedListContentsStyle}>
        {memoList?.map(memo => {
          return (
            <div
              key={memo.reservationId}
              css={!memo.noteRead ? memoListItemUnreadStyle : memoListItemReadStyle}
              onClick={() => {
                putAdminReadReservationNote(memo.reservationId)
                changePage(memo)
              }}
            >
              <div css={dataRoomNumberStyle}>
                <div css={roomNumberStyle}>{memo.roomNumbers?.length ? `${memo.roomNumbers}` : '-'}</div>
              </div>
              <div css={dataNameStyle}>
                <div css={roomNumberStyle}>{memo.name ?? '-'}</div>
              </div>
              <div css={dataCustomerTagStyle}>
                {memo.customerTags.map(tag => (
                  <CustomerTag key={tag.id} fontColor={tag.fontColor} bgColor={tag.backgroundColor} name={tag.name} />
                ))}
              </div>
              <div css={memoStyle}>
                <div>
                  <img src={require(`@/static/images/reservation-note/icon_front.svg`)} alt="" />
                  <p>{memo.notes.find(({ category }) => isFrontMemo(category))?.memo || '-'}</p>
                </div>
                <div>
                  <img src={require(`@/static/images/reservation-note/icon_cleaning.svg`)} alt="" />
                  <p>{memo.notes.find(({ category }) => isCleaningMemo(category))?.memo || '-'}</p>
                </div>
                <div>
                  <img src={require(`@/static/images/reservation-note/icon_meal.svg`)} alt="" />
                  <p>{memo.notes.find(({ category }) => isMealMemo(category))?.memo || '-'}</p>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const mixedSectionStyle = css(sectionStyle, {
  marginTop: 16,
  overflow: 'hidden',
})

const headerWrapperStyle = css({
  display: 'flex',
  alignItems: 'center',
  height: 28,
  background: '#FAFAFA',
  borderTop: '1px solid #F2F2F2',
  borderBottom: '1px solid #F2F2F2',
  fontSize: 10,
  color: '#676767',
  fontWeight: 'bold',
  padding: '0 40px 0 32px',
})

const titleMemoStyle = css({
  width: '62.5%',
})

const titleRoomNumberStyle = css({
  width: '7.7%',
})

const titleCustomerTagStyle = css({
  width: '18.9%',
})

const titleNameStyle = css({
  width: '11.4%',
})

const mixedListContentsStyle = css(listContentsStyle, {
  height: 580,
  overflowX: 'hidden',
  overflowY: 'auto',
})

const dataRoomNumberStyle = css({
  width: '7.7%',
  display: 'flex',
  alignItems: 'center',
})

const dataNameStyle = css({
  width: '11.4%',
  display: 'flex',
  alignItems: 'center',
})

const dataCustomerTagStyle = css({
  width: '18.9%',
  display: 'flex',
  flexWrap: 'wrap',
  gap: 8,
})

// list detail
const memoStyle = css({
  width: '62.5%',
  div: {
    display: 'flex',
    alignItems: 'center',
    gap: 14,
    '& + div': {
      marginTop: 12,
    },
  },
  p: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    lineHeight: 1.5,
  },
})

const roomNumberStyle = css({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
})

const memoListItemUnreadStyle = css(listItemUnreadStyle, {
  fontSize: 12,
  alignItems: 'flex-start',
  fontWeight: 'bold',
  padding: '16px 32px',
})

const memoListItemReadStyle = css(listItemReadStyle, {
  fontSize: 12,
  fontWeight: 'bold',
  padding: '16px 32px',
})
