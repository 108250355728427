export enum ReservationNoteCategory {
  Front = 'front',
  Cleaning = 'cleaning',
  Meal = 'meal',
}
export type ReservationMemos = {
  [key in ReservationNoteCategory]?: string
}
export interface ReservationNote {
  category: ReservationNoteCategory
  checkinId: string | null
  createdAt: string
  hotelId: string
  memo: string
  noteId: string
  reservationId: string
  updatedAt: string
}
export interface MemoWithCategory {
  category: string
  memo: string
}
export interface DashboardNote {
  id: string
  reservationId: string
  checkinId: string | null
  name: string | null
  noteRead: boolean
  notes: { category: string; memo: string | null }[]
  customerTags: { id: string; name: string; fontColor: string; backgroundColor: string }[]
  roomNumbers: string[] | undefined
}

export const hasMemoByCategory = (reservationNotes: ReservationNote[], category: ReservationNoteCategory) => {
  return !!reservationNotes.find(reservationNote => reservationNote.category === category)?.memo
}

export const isFrontMemo = (category: string) => {
  return category === ReservationNoteCategory.Front
}

export const isCleaningMemo = (category: string) => {
  return category === ReservationNoteCategory.Cleaning
}

export const isMealMemo = (category: string) => {
  return category === ReservationNoteCategory.Meal
}

export const hasCleaningMemo = (reservationNotes: MemoWithCategory[] | undefined) => {
  if (!reservationNotes) {
    return false
  }

  return !!reservationNotes.find(reservationNote => reservationNote.category === ReservationNoteCategory.Cleaning)
}
