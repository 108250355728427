import React from 'react'
import uuid from 'react-uuid'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { env } from '@/libs/env'
import { updateCheckinIdentification } from '@/apis/aipass'
import { IdentificationType } from '@/models/self-checkin/identification'
import { useFileUpload } from '@/hooks/use-file-upload'

const uploadImagePath = env('SELF_CHECKIN_IMAGE_PATH')

type Props = {
  checkinId: string
  identifications: any
  hotelId?: string
  onClickDeleteImage?: (e: any) => void
}

export const Identification: React.FC<Props> = ({ checkinId, identifications, hotelId, onClickDeleteImage }) => {
  const { t } = useTranslation()
  const { upload } = useFileUpload()

  // Image addition processing
  const handleAddFile = (e: any) => {
    const files = e.target.files
    if (files.length > 0) {
      const file = files[0]
      const reader = new FileReader()
      reader.onload = function (e: any) {
        const blob = new Blob([new Uint8Array(e.target.result)], { type: file.type })
        const imageKey = `${hotelId}/${uploadImagePath}/${uuid()}.png`
        upload({
          hotelId: hotelId!,
          file,
          fileData: blob,
          filePath: imageKey,
          isPublic: false,
        }).then(() => {
          insertIdentification(file.name, imageKey)
        })
      }
      reader.readAsArrayBuffer(file)
    } else {
      console.log(t('Upload failed'))
    }
  }

  // Add ID
  const insertIdentification = (name, imageKey) => {
    const identification: IdentificationType[] = [
      {
        checkinId: checkinId,
        name: name,
        imageKey: imageKey,
        isDriversLicense: false,
      },
    ]
    updateCheckinIdentification(identification).then(() => {
      window.location.reload()
    })
  }

  return (
    <>
      <div>
        <div css={wrapperStyle}>
          <div css={imageListStyle}>
            {!!identifications?.length &&
              identifications.map((v: any, index) => (
                <div key={v.identificationId}>
                  <img
                    onClick={onClickDeleteImage}
                    data-identificationId={v.identificationId}
                    src={require('@/static/images/deleat_icon.svg')}
                    css={identificationImageDeleteButtonStyle}
                  />
                  <img id={v.identificationId} src={v.imageKey} css={identificationImageStyle} />
                </div>
              ))}
            <div css={imageNotListStyle}></div>
            <label htmlFor="identificationNumber" css={addIdentificationContainerStyle}>
              <img src={require('@/static/images/plus.svg')} />
              <p>{t('Add ID')}</p>
            </label>
            <input
              type="file"
              id="identificationNumber"
              css={inputNoneStyle}
              onChange={e => handleAddFile(e)}
              onClick={(e: any) => {
                e.target.value = ''
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

const wrapperStyle = css({
  margin: '9px 32px 16px 16px',
  position: 'relative',
  justifyContent: 'left',
  fontSize: 12,
  color: '#676767',
})

// 身分証を追加
const addIdentificationContainerStyle = css({
  cursor: 'pointer',
  display: 'flex',
  height: 96,
  width: 375,
  backgroundColor: '#FFF',
  justifyContent: 'center',
  border: '1px dashed #CCCCCC',
  borderRadius: 6,
  p: {
    color: '#F2A40B',
    fontSize: 14,
    fontWeight: 'bold',
  },
  alignItems: 'center',
})

const inputNoneStyle = css({
  display: 'none',
})

const imageListStyle = css({
  alignItems: 'center',
})

const imageNotListStyle = css({
  marginTop: '24px',
})

const identificationImageStyle = css({
  maxWidth: 375,
  borderRadius: 6,
  marginTop: 15,
  marginRight: 10,
  marginBottom: 16,
})

const identificationImageDeleteButtonStyle = css({
  position: 'absolute',
  right: 0,
  height: 30,
  borderRadius: '50%',
  background: '#ffffff',
  '&:hover': {
    cursor: 'pointer',
  },
})
