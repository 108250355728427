import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { Button } from '@/components/atoms'
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@/components/atoms/modal'
import { NoticeLoading } from '@/components/molecules/notice-loading'
import { Pagination } from '@/components/molecules/pagination'
import { fetchAdvanceMoneyHistoryByCompany } from '@/apis/aipass'
import dayjs from 'dayjs'
import { useHistory } from 'react-router-dom'

type AdvanceMoneyHistoryProps = {
  setIsOpenModal: (id: string) => void
  advanceMoneyCompanyId: string
}

const LIMIT = 30

export const AdvanceMoneyHistoryModal = ({ setIsOpenModal, advanceMoneyCompanyId }: AdvanceMoneyHistoryProps) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [companyName, setCompanyName] = useState('')
  const [pagination, setPagination] = useState<any>({
    page: 1,
    hasNext: false,
    listTotal: 0,
    hasList: 0,
    limit: LIMIT,
  })
  const [companyAdvanceMoneyHistory, setCompanyAdvanceMoneyHistory] = useState<any[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const getPaginateAdvanceMoney = useCallback(async (companyId, currentPage) => {
    try {
      setIsLoading(true)
      const res = await fetchAdvanceMoneyHistoryByCompany(companyId, currentPage, LIMIT)
      setCompanyName(res.companyName)
      setCompanyAdvanceMoneyHistory(res.histories)
      setPagination({
        page: res.pagination.page,
        hasNext: res.pagination.page < res.pagination.totalPage,
        listTotal: res.pagination.total,
        hasList: res.histories.length,
        limit: LIMIT,
      })
    } catch (error) {
      console.log(t('Communication failed'))
    } finally {
      setIsLoading(false)
    }
  }, [])

  const goToAccommodationDetail = advanceMoneyHistory => {
    if (advanceMoneyHistory.checkin_id) {
      history.push({
        pathname: `/checkin/${advanceMoneyHistory.checkin_id}`,
        search: `?reservationId=${advanceMoneyHistory.reservation_id}`,
      })
    } else if (advanceMoneyHistory.reservation_index_id) {
      history.push({
        pathname: `/reservation/${advanceMoneyHistory.reservation_index_id}`,
        search: `?reservationId=${advanceMoneyHistory.reservation_id}`,
      })
    }
  }

  useEffect(() => {
    getPaginateAdvanceMoney(advanceMoneyCompanyId, 1)
  }, [])

  return (
    <Modal customCss={{ minWidth: 780 }}>
      <ModalHeader>
        <div>{t('History of using')}</div>
      </ModalHeader>
      <ModalBody customCss={{ paddingBottom: 12 }}>
        <NoticeLoading isLoading={isLoading} />
        <>
          <div css={modalHistoryHeaderTextStyle}>
            <span>{t('Company name')} :</span>
            <p>{companyName}</p>
          </div>
          <div css={modalHistoryMainStyle}>
            <div className="title">
              <div className="checkinDate">{t('Check-in date')}</div>
              <div className="paymentDatetime">{t('Withdrawal date')}</div>
              <div className="reservationPerson">{t('Guest')}</div>
              <div className="initialAmount">{t('Withdrawal amount')}</div>
              <div className="balance">{t('Balance')}</div>
            </div>
            <div className="item">
              <ul>
                {companyAdvanceMoneyHistory.map((item, index) => (
                  <li key={index}>
                    <div className="checkinDate">{dayjs(item.checkin_date).format(t('MM-DD'))}</div>
                    <div className="paymentDatetime">
                      {item.payment_date_unixtime === null ? '-' : dayjs.unix(item.payment_date_unixtime).format(t('MM-DD-YYYY'))}
                    </div>
                    <div className="reservationPerson" onClick={() => goToAccommodationDetail(item)}>
                      <span className="-ellipsis-word">{item.reservation_guest_name}</span>
                    </div>
                    <div className="initialAmount">
                      <span className="-ellipsis-word">¥{item.payment_amount.toLocaleString()}</span>
                    </div>
                    <div className="balance">
                      <span className="-ellipsis-word">¥{item.balance.toLocaleString()}</span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <Pagination
            page={pagination.page}
            hasNext={pagination.hasNext}
            listTotal={pagination.listTotal}
            hasList={pagination.hasList}
            limit={pagination.limit}
            setPage={page => getPaginateAdvanceMoney(advanceMoneyCompanyId, page)}
          />
        </>
      </ModalBody>
      <ModalFooter>
        <Button
          isDisabled={false}
          buttonType={3}
          height="38px"
          width="110px"
          onClick={() => {
            setIsOpenModal(advanceMoneyCompanyId)
          }}
        >
          {t('Cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const modalHistoryHeaderTextStyle = css({
  display: 'flex',
  marginBottom: 17,
  span: {
    letterSpacing: '0.7px',
    marginRight: 12,
    color: '#A3A3A3',
    fontSize: 14,
  },
  p: {
    color: '#272727',
    fontSize: 14,
    letterSpacing: '0.7px',
  },
})

const modalHistoryMainStyle = css({
  backgroundColor: '#FFFFFF',
  borderRadius: '5px',
  marginTop: '17px',
  border: '1px solid #cccccc',
  height: 316,

  '.checkinDate': { minWidth: '18%', maxWidth: '18%' },
  '.paymentDatetime': { minWidth: '22%', maxWidth: '22%' },
  '.reservationPerson': { minWidth: '32%', maxWidth: '32%' },
  '.initialAmount': { minWidth: '14%', maxWidth: '14%' },
  '.balance': { minWidth: '14%', maxWidth: '14%' },
  '.-ellipsis-word': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
  },

  '.title': {
    display: 'flex',
    alignItems: 'center',
    height: '33px',
    width: '100%',
    padding: '12px 22px 10px 22px',
    fontSize: '12px',
    color: '#676767',
    borderBottom: '1px solid #F2F2F2',
    '> div': {
      fontWeight: 'bold',
      textAlign: 'left',
      overflowWrap: 'anywhere',
      lineHeight: '1.8px',
      letterSpacing: '0.6px',
    },
  },
  '.item': {
    ul: { overflow: 'auto', height: '281px' },
    li: {
      display: 'flex',
      alignItems: 'center',
      height: '35px',
      padding: '12px 22px',
      fontSize: '12px',
      color: '#272727',
      borderBottom: '1px solid #F2F2F2',
      '.reservationPerson': {
        color: '#F2A40B',
        span: { whiteSpace: 'pre' },
        cursor: 'pointer',
      },
    },
    'li > div': {
      letterSpacing: '0.48px',
      lineHeight: '19px',
      fontWeight: 'normal',
      color: '#272727',
    },
  },
})
