import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { css } from '@emotion/core'
import parse from 'html-react-parser'

// apis
import { fetchGuide, fetchReservationsAndSales } from '@/apis/aipass'

// components
import { SelfCheckinHeader } from '@/components/molecules'
import { SelfCheckinFooter } from '@/components/molecules/self-checkin/footer'

// context
import { CustomCheckin } from '@/components/organisms/self-checkin/custom-checkin'
import { Loading } from '@/components/molecules/self-checkin/loading'

// libs
import { setHasPaymentPlugin } from '@/libs/plugins'

// utils
import { checkValidateGuidesRequired, addValidateGuidesRequired, changeValidateGuidesRequired } from '@/utils/guide'

export type Guide = {
  customCheckinId: string
  jpTitle: string
  enTitle: string
  zhTitle: string
  koTitle: string
  jpContent: string
  enContent: string
  zhContent: string
  koContent: string
  inputType: 'none' | 'textArea' | 'selectBox'
  jpInputValue: any
  isRequired: boolean
  selectedAnswer?: string
}

export const CheckinCustomize: React.FC<{}> = () => {
  const { search, state } = useLocation<{
    guides: any
    checkinMemo: any
    hotelId: string
    selectedReservations: any
    from: string
    paymentInfo: any
    requiredIdentify: any
    noneSelectReservation: any
    plugin: any
    paymentSetting: any
  }>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { t, i18n } = useTranslation()
  const lang = i18n.language
  const [guides, setGuides] = useState<Guide[]>([])
  const [validGuides, setValidGuides] = useState<any>([])
  const [options, setOptions] = useState<any>()
  const [selectedInputValue, setSelectedInputValue] = useState<any>([])
  const history = useHistory()
  console.log(state)

  if (!state?.hotelId) {
    history.replace({ pathname: '/dashboard' })
  }

  const getTitle = guide => {
    if (lang === 'ja') {
      return parse(guide.jpTitle)
    }
    if (lang === 'en') {
      return parse(guide.enTitle)
    }
    if (lang === 'ko') {
      return parse(guide.koTitle)
    }
    if (lang === 'zh') {
      return parse(guide.zhTitle)
    }
  }

  const getContent = guide => {
    if (lang === 'ja') {
      return parse(guide.jpContent)
    }
    if (lang === 'en') {
      return parse(guide.enContent)
    }
    if (lang === 'ko') {
      return parse(guide.koContent)
    }
    if (lang === 'zh') {
      return parse(guide.zhContent)
    }
  }

  const getOption = guide => {
    if (lang === 'ja') {
      return guide.jpInputValue
    }
    if (lang === 'en') {
      return guide.enInputValue
    }
    if (lang === 'ko') {
      return guide.koInputValue
    }
    if (lang === 'zh') {
      return guide.zhInputValue
    }
  }

  const filterUniqueValueById = selectedInputValue => {
    const customCheckinIds = selectedInputValue.map(v => {
      return v.customCheckinId
    })
    return selectedInputValue.filter((v, index) => {
      // keep the last choice
      return customCheckinIds.lastIndexOf(v.customCheckinId) === index
    })
  }

  const onChangeState = (e: React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLTextAreaElement>, customCheckinId) => {
    if (!e.target.value) {
      const addValidateGuides = addValidateGuidesRequired(customCheckinId, guides)
      if (addValidateGuides.length > 0) {
        const errorMessage = document.getElementById(addValidateGuides[0]['customCheckinId'] + '-error')
        errorMessage ? (errorMessage.style.visibility = 'visible') : ''
      }
      setValidGuides(addValidateGuides.concat(validGuides))
    } else {
      const changeValidateGuides = changeValidateGuidesRequired(customCheckinId, validGuides)
      const errorMessage = document.getElementById(customCheckinId + '-error')
      errorMessage && errorMessage.style.visibility === 'visible' ? (errorMessage.style.visibility = 'hidden') : ''
      setValidGuides(changeValidateGuides)
    }
    const value = e.target.value
    const name = e.target.name
    const newSlectedInputValue = [...selectedInputValue, { customCheckinId: customCheckinId, [name]: value }]
    setSelectedInputValue(filterUniqueValueById(newSlectedInputValue))
  }

  const _fetchGuide = async () => {
    setIsLoading(true)
    const res = await fetchGuide(state?.hotelId)
    if (res?.customCheckin) {
      setGuides(res.customCheckin)
      const isRequiredGuides = checkValidateGuidesRequired(res?.customCheckin)
      setValidGuides(isRequiredGuides)
    } else {
      // page skip
      onClickConfirm(null)
    }
    // Since it is displayed for a moment, adjust with setTimeout
    setTimeout(() => setIsLoading(false), 500)
  }

  // Replace form content with input content, not filled in with -
  const replaceInputValue = () => {
    const _guides = [...guides]
    _guides.map(guide => {
      selectedInputValue.map(v => {
        if (v.customCheckinId === guide.customCheckinId) {
          // When you reselect Please select
          if (v.value === '') {
            guide.selectedAnswer = '-'
          } else {
            guide.selectedAnswer = v.value
          }
        }
      })
      // when not choosing
      if (!guide.selectedAnswer) {
        guide.selectedAnswer = '-'
      }
    })
    setGuides(_guides || [])
  }

  const isDisabled = (): boolean => {
    return guides.length === 0 || validGuides.length > 0
  }

  const onClickConfirm = checkinMemo => {
    state.guides = guides
    state.checkinMemo = checkinMemo

    if (state.noneSelectReservation && state?.requiredIdentify) {
      let uploadPath = '/self-checkin/identity-verify/none-select-resvervation/upload-license'

      if (i18n.language !== 'ja') {
        uploadPath = `/self-checkin/identity-verify/none-select-resvervation/upload-passport`
      }

      history.push({
        pathname: uploadPath,
        search,
        state,
      })

      return
    } else if (state.noneSelectReservation) {
      history.push({
        pathname: '/self-checkin/none-select-resvervation/confirm',
        search,
        state,
      })

      return
    }

    setIsLoading(true)
    fetchReservationsAndSales(state.hotelId, state.selectedReservations)
      .then(res => {
        if (setHasPaymentPlugin(state.plugin) && state.paymentSetting.length) {
          if (res?.totalAmount > 0) {
            history.push({
              pathname: '/self-checkin/payment',
              search,
              state,
            })
            return
          }
        }

        delete state.paymentInfo
        if (state?.requiredIdentify) {
          let uploadPath = `/self-checkin/identity-verify/upload-license`

          if (i18n.language !== 'ja') {
            uploadPath = `/self-checkin/identity-verify/upload-passport`
          }

          history.push({
            pathname: uploadPath,
            search,
            state,
          })
        } else {
          history.push({
            pathname: `/self-checkin/confirm`,
            search,
            state,
          })
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const goBackToForm = () => {
    replaceInputValue()
    const nav = t('Information from the facility')
    const notes = guides.map(guide => {
      if (guide.inputType !== 'none') {
        return `${guide.jpTitle}:${guide.selectedAnswer}`
      }
    })
    const filteredNotes = notes.filter(v => v)
    let checkinMemo: string = ''
    if (filteredNotes.length !== 0) {
      checkinMemo = [nav, ...filteredNotes].join('\n')
    } else {
      checkinMemo = ''
    }

    state.guides = guides
    state.checkinMemo = checkinMemo
    history.push({
      pathname: '/self-checkin/accommodation-info',
      search,
      state,
    })
  }

  const _checkBlank = () => {
    replaceInputValue()
    const nav = t('Information from the facility')
    const notes = guides.map(guide => {
      if (guide.inputType !== 'none') {
        return `${guide.jpTitle}:${guide.selectedAnswer}`
      }
    })
    const filteredNotes = notes.filter(v => v)
    let checkinMemo: string = ''
    if (filteredNotes.length !== 0) {
      checkinMemo = [nav, ...filteredNotes].join('\n')
    } else {
      checkinMemo = ''
    }
    onClickConfirm(checkinMemo)
  }

  useEffect(() => {
    if (!state?.hotelId) {
      return
    }
    window.scrollTo(0, 0)
    if (state.guides?.length) {
      setGuides(state.guides)
      setIsLoading(false)
      return
    }
    _fetchGuide()
  }, [])

  useEffect(() => {
    if (guides) {
      setOptions({})
      guides.map((guide, index) => {
        const translatedOption = getOption(guide)
        setOptions(prevOptions => ({
          ...prevOptions,
          [index]: translatedOption,
        }))
      })
    }
  }, [guides, lang])

  return (
    <div css={containerStyle}>
      <SelfCheckinHeader goToPreviousPage={goBackToForm} title={t('Guidance')} />
      <div css={mainStyle}>
        <div css={containerWrapperStyle}>
          {guides?.map((guide, index) => (
            <CustomCheckin
              key={index}
              index={index}
              guide={guide}
              options={options}
              selectedInputValue={selectedInputValue}
              onChangeState={onChangeState}
              getTitle={getTitle}
              getContent={getContent}
            />
          ))}
        </div>
        <SelfCheckinFooter isDisabled={isDisabled} goToNextPage={_checkBlank} isNext={'next'} />
      </div>
      <Loading isLoading={isLoading} />
    </div>
  )
}

const containerStyle = css({
  width: '100%',
  position: 'relative',
  fontFamily: 'Noto Sans JP',
  backgroundColor: '#F2F2F2',
  minHeight: '100vh',
})

const mainStyle = css({
  padding: '112px 0',
})

const containerWrapperStyle = css({
  margin: 'auto',
})
