import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import 'react-dates/initialize'

// api
import { updateAdminReservationChannelCode, putAdminReservation, updateAdminReservationMarketSegment } from '@/apis/aipass'

// components
import { LoadingFull } from '@/components/molecules/loading-full'
import { Button } from '@/components/atoms/button'
import { useErrorHandler } from '@/hooks/use-error-handler'

// extends dayjs
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { useAssignConfirmDialog } from './use-assign-confirm-dialog'
import { ReservationForm, ReservationFormType } from './_detail-edit-info/reservation-form'
dayjs.extend(isSameOrBefore)

type DetailInfoProps = {
  reservation?: any
  isEdit: boolean
  setIsEdit: (v: boolean) => void
}

export const DetailEditInfo: React.FC<DetailInfoProps> = ({ reservation, isEdit, setIsEdit }) => {
  const { t } = useTranslation()
  const { errorHandler, isOutOfStockError } = useErrorHandler()

  // Accommodation date before change
  const checkinDateBeforeChanged = reservation.checkinDate
  const checkoutDateBeforeChanged = reservation.checkoutDate

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [reservationForm, setReservationForm] = useState<ReservationFormType>({
    plan: reservation.plan,
    checkinDate: reservation.checkinDate,
    checkoutDate: reservation.checkoutDate,
    checkinTime: reservation.checkinTime,
    checkoutTime: reservation.checkoutTime,
    male: reservation.paxMale,
    female: reservation.paxFemale,
    childA: reservation.paxChildA,
    childB: reservation.paxChildB,
    childC: reservation.paxChildC,
    childD: reservation.paxChildD,
    childE: reservation.paxChildE,
    childF: reservation.paxChildF,
    childOther: reservation.paxChildOther,
    accommodationFee: reservation.accommodationFee,
    pointDiscount: reservation.pointDiscount,
    payment: reservation.payment,
    notes: reservation.notes,
    channelCodeId: reservation.channelCodeId,
    marketSegmentId: reservation.marketSegmentId,
    meal: reservation.meal,
  })
  const { AssignConfirmDialog, openConfirmDialog } = useAssignConfirmDialog()
  const [infoErrors, setInfoErrors] = useState<string[]>([])

  const onSaveData = async () => {
    try {
      setIsLoading(true)
      if (infoErrors.length) {
        window.alert(t(infoErrors[0]))
        return
      }

      try {
        await putReservationInfo({ validationOnly: true })
      } catch (e) {
        if (!isOutOfStockError(e as Error)) {
          throw e
        }
        setIsLoading(false)
        if (!(await openConfirmDialog())) {
          return
        }
        setIsLoading(true)
      }
      updateAdminReservationChannelCode(reservation.reservationId, reservationForm.channelCodeId)
      updateAdminReservationMarketSegment(reservation.reservationId, reservationForm.marketSegmentId)
      await putReservationInfo({ excludeStockCheck: true })
      setIsEdit(false)
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
    }
  }

  const putReservationInfo = async (option?: { validationOnly?: boolean; excludeStockCheck?: boolean }) => {
    // If the accommodation date has not changed, pass the accommodation date and time before the change
    const isChangedCheckinDate =
      dayjs(reservationForm.checkinDate).format('YYYY-MM-DD') !== dayjs(checkinDateBeforeChanged).format('YYYY-MM-DD')
    const isChangedCheckoutDate =
      dayjs(reservationForm.checkoutDate).format('YYYY-MM-DD') !== dayjs(checkoutDateBeforeChanged).format('YYYY-MM-DD')
    const reservationCheckinDate = isChangedCheckinDate ? reservationForm.checkinDate : checkinDateBeforeChanged
    const reservationCheckoutDate = isChangedCheckoutDate ? reservationForm.checkoutDate : checkoutDateBeforeChanged

    const formatCheckinDate = dayjs(reservationCheckinDate).format('YYYY-MM-DD')
    const formatCheckoutDate = dayjs(reservationCheckoutDate).format('YYYY-MM-DD')
    const nights = reservationCheckoutDate ? dayjs(formatCheckoutDate).diff(dayjs(formatCheckinDate), 'day') : 0

    const data = {
      reservationId: reservation.reservationId,
      reservation: {
        ...reservationForm,
        checkinDate: isChangedCheckinDate
          ? dayjs(reservationCheckinDate).add(12, 'h').format('YYYY-MM-DD')
          : dayjs(reservationCheckinDate).format('YYYY-MM-DD'),
        checkoutDate: isChangedCheckoutDate
          ? dayjs(reservationCheckoutDate).add(12, 'h').format('YYYY-MM-DD')
          : dayjs(reservationCheckoutDate).format('YYYY-MM-DD'),
        nights,
        method: reservation.method,
        optionStatus: reservation.reservationStatus,
      },
    }
    await putAdminReservation({ ...data, ...option })
  }

  return (
    <React.Fragment>
      <div css={modalWrapperStyle}>
        <div css={modalContentStyle}>
          <div css={modalTitleWrapperStyle}>
            <p css={modalTitleStyle}>{t('Edit booking information')}</p>
          </div>

          <div css={addGuestInfoWrapperStyle}>
            <ReservationForm reservationForm={reservationForm} setReservationForm={setReservationForm} setErrors={setInfoErrors} />
          </div>

          <div>
            <div css={buttomButtonStyle}>
              <Button width={110} height={38} buttonType={3} fontSize={12} marginRight={16} onClick={() => setIsEdit(!isEdit)}>
                {t('Cancel')}
              </Button>
              <Button width={110} height={38} buttonType={1} fontSize={12} onClick={() => onSaveData()}>
                {t('Save')}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <AssignConfirmDialog />
      <LoadingFull isLoading={isLoading} />
    </React.Fragment>
  )
}

// Modal
const modalWrapperStyle = css({
  width: '100vw',
  height: '100vh',
  background: 'rgba(39, 39, 39, 0.5)',
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 101,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'scroll',
})

const modalContentStyle = css({
  width: 900,
  maxHeight: 650,
  background: '#fff',
  borderRadius: 5,
  marginBottom: 40,
})

const modalTitleWrapperStyle = css({
  width: 'inherit',
  paddingLeft: 32,
  position: 'absolute',
  'z-index': '10000',
  backgroundColor: 'white',
  borderRadius: 5,
})

const modalTitleStyle = css({
  color: '#272727',
  fontSize: 18,
  fontWeight: 'bold',
  lineHeight: '60px',
  letterSpacing: '0.9px',
})

const addGuestInfoWrapperStyle = css({
  width: '100%',
  padding: '92px 32px 32px',
  background: '#f2f2f2',
  justifyContent: 'space-between',
  borderRadius: '5px 5px 0px 0px',
})

const buttomButtonStyle = css({
  display: 'flex',
  padding: '11px 32px',
  justifyContent: 'flex-end',
  backgroundColor: '#FFFFFF',
  borderRadius: '0px 0px 5px 5px',
})
